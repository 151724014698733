@import url("https://fonts.googleapis.com/css?family=Open+Sans");

@media (min-width: $lg-breakpoint) {
  .nx-grid--max-width {
    max-width: 1184px !important;
  }
}

.nx-card {
  background-color: $white !important;
  position: relative !important;
}

.nx-card-inner {
  padding: unset !important;
}

.nx-button--primary {
  color: $white !important;
}

.nx-link {
  color: $allianz-blue !important;
}

.nx-button--secondary {
  color: $allianz-blue !important;
}

.nx-button--secondary:hover:not([disabled]):not(:active) {
  color: $white !important;
  background-color: $allianz-blue !important;
  border-color: $allianz-blue !important;
}

.nx-heading--subsection-small {
  color: inherit !important;
}

.nx-card .media {
  background-repeat: no-repeat !important;
  height: 163px !important;
  background-position: center !important;
  position: relative !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.nx-card.voucher--card .media {
  background-color: $carousel-bg !important;
}

.nx-card .media .image-div {
  max-width: 220px !important;
  width: 100% !important;
  max-height: 140px !important;
  height: 100% !important;
  border-radius: 8px !important;
  background-position: center !important;
  position: relative !important;
}

.nx-card .body p {
  margin-bottom: 4px !important;
}

.nx-pagesearch {
  background: inherit !important;
}

.nx-formfield {
  color: inherit !important;
}

.nx-formfield__input-container {
  border: 1px solid inherit !important;
  border-color: inherit !important;
}

.c-input {
  color: inherit !important;
}

.nx-button--primary {
  color: $white !important;
  background-color: $progress_bar !important;
}

.nx-button--primary:hover:not(:disabled):not(:active) {
  background-color: $allianz-blue !important;
}

.navigation-row {
  margin: auto;
}

.nav-bar-sticky
  .nx-grid--max-width
  .nx-header__row
  .nx-header__navigation-items
  .nx-header__link.is-active {
  color: $allianz-blue !important;
  border-color: $allianz-blue !important;
}

.nav-bar-sticky
  .nx-grid--max-width
  .nx-header__row
  .nx-header__navigation-items
  .nx-header__link:hover {
  border-color: $allianz-blue !important;
  color: $allianz-blue !important;
}

.filter-group {
  .nx-tab-header__item.nx-tab-header__item--active {
    display: none;
  }

  .nx-button--primary {
    background-color: inherit !important;
    color: inherit !important;
  }
}

input:focus,
.nx-formfield__input-container:focus,
.nx-formfield:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

input[nxInput]::placeholder {
  color: $black-fade !important;
}

.shopping-benefits--page
  .shopping-benefits--filters
  .main-content--row
  .shopping-filters
  .nx-formfield__input-container
  nx-dropdown
  .nx-dropdown__container
  .nx-dropdown__rendered {
  color: $black-fade !important;
  font-weight: 300;
}

.nx-dropdown__panel-body {
  background-color: $white !important;
}

.nx-dropdown-results__option-label {
  color: $black-fade !important;
  box-shadow: unset !important;
  border: unset !important;
}

.nx-dropdown__panel-header {
  background-color: $carousel-bg !important;
  color: $black-fade !important;
}

.nx-dropdown-results__option:hover > .nx-dropdown-results__option-label {
  color: $allianz-blue !important;
}

.nx-dropdown__panel {
  box-shadow: 0 2px 4px rgba(65, 65, 65, 0.5) !important;
  border-radius: 4px !important;
}

.nx-expansion-panel__header-content {
  background-color: inherit !important;
}

.faq-block
  .nx-expansion-panel__header-content
  .nx-expansion-panel__header-title {
  max-width: 712px;
  width: 100%;
  color: $allianz-blue !important;
  font-size: 20px;
  line-height: 28px;
}

absolute .faq-accordion .nx-expansion-panel__header-content,
.faq-accordion .nx-expansion-panel__header-content {
  padding: 24px 32px !important;
}

.faq-block .nx-expansion-panel__body {
  padding: 0 32px 24px !important;
  max-width: 776px;
  margin-right: auto;
}

.faq-block .nx-expansion-panel__chevron {
  color: $allianz-blue !important;
}

.faq-search p {
  margin: 0 !important;
  max-width: 250px;
  width: 100%;
}

input[nxInput]::placeholder {
  color: $black-fade !important;
}

.shopping-benefits--filters .nx-tab-header__item.nx-tab-header__item--active {
  border-width: 4px;
  transition: font-weight 0.3s;
  color: $allianz-blue !important;
  border-color: $allianz-blue !important;
  padding-right: 0 !important;
  letter-spacing: 1px;
  font-weight: 700;
  padding-left: 0 !important;
  width: 166px !important;
}

.shopping-benefits--filters
  .nx-tab-header
  > button.nx-tab-header__item:last-child {
  padding-left: 0 !important;
  padding-right: 0 !important;
  letter-spacing: 1px;
  color: $allianz-blue !important;
  padding-bottom: 4px !important;
}

.tab__content .nx-grid__row {
  justify-content: center !important;
}

.tab__content .nx-message__content {
  color: $black-fade !important;
}

.hero-section p {
  margin: auto !important;
}

.nx-popover__content {
  background-color: $white !important;
  border: 1px solid $light-grey !important;
  box-shadow: 0 2px 4px rgba(65, 65, 65, 0.5) !important;
}

.nx-button--tertiary {
  color: $allianz-blue !important;
}

.nx-button--tertiary:hover:not(:disabled):not(:active) {
  background-color: $carousel-bg !important;
  color: $allianz-blue !important;
  border: transparent !important;
}

.cart-row--modifier {
  align-items: center !important;
  justify-content: end !important;
}

.nx-heading--section,
.voucher--page .voucher-related-products--heading,
.nx-heading--subsection-large {
  color: inherit !important;
}

.nx-checkbox__label,
.nx-stepper__input {
  color: $black-fade !important;
}

.nx-grid.nx-grid--media-query {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.nx-checkbox__input:checked + .nx-checkbox__label .nx-checkbox__control {
  background-color: $progress_bar !important;
  border-color: $progress_bar !important;
  color: $white !important;
}

.payment-buttons,
.payment-button--modal {
  justify-content: center !important;
}

.nx-message__content,
.nx-radio__label {
  color: $black-fade !important;
}

.nx-modal__container {
  border-radius: 4px;
  box-shadow: 0 8px 24px rgba(65, 65, 65, 0.35);
  background: $white;
  color: $black-fade;
}

.nx-button--primary:disabled {
  background-color: rgba(0, 122, 179, 0.4) !important;
  color: rgba(255, 255, 255, 0.4) !important;
  border-color: transparent !important;
}

.nx-radio__circle {
  border-color: $top-label !important;
  border-radius: 50%;
  width: 24px;
  min-width: 24px;
  height: 24px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid $top-label !important;
}

.nx-radio__input:checked + .nx-radio__label .nx-radio__dot {
  background-color: $progress_bar !important;
}

.nx-radio__input:checked + .nx-radio__label .nx-radio__circle {
  border-color: $progress_bar !important;
  background-color: transparent !important;
}

.nx-formfield {
  color: $black-fade !important;
}

.nx-formfield.has-error:not(.nx-formfield--negative) {
  color: $expired-color !important;
}

.nx-modal__content-wrapper {
  background-color: $white !important;
}

.nx-formfield__label {
  color: $black-fade !important;
}

.nx-formfield.has-error:not(.nx-formfield--negative):not(.has-outline)
  .nx-formfield__label {
  color: $danger-color !important;
}

.progress {
  justify-content: center !important;
}

.nx-spinner__spin-block {
  width: 50px !important;
  height: 50px !important;
  justify-content: center !important;
  margin: auto !important;
  text-align: center !important;
}

.faq-link--topblock p {
  max-width: 928px;
  width: 100%;
  color: $black-fade !important;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin: 0 auto !important;
}

.btn-center {
  margin: auto !important;
}

.nx-stepper__input-underline {
  width: 100%;
  margin-top: 2px;
  height: 2px;
  background: $black-fade !important;
}

.timing-data-row {
  justify-content: center !important;
}

.ticket-category--section .ticket-category--block.is-card-selected {
  background-color: $main-gbg !important;
  border: 1px solid $light-grey !important;
}

.nx-spinner--small .nx-spinner__spin-block {
  border-width: 5px !important;
}

nx-spinner.nx-spinner--small {
  width: unset !important;
  height: unset !important;
}

.nx-spinner__spin-block {
  border-color: $progress_bar $link-row $link-row !important;
  border-color: $link-row !important;
  border-top-color: $progress_bar !important;
}

@media (max-width: $lg-breakpoint) {
  .newsletter--card,
  .homepage--card {
    max-height: unset !important;
  }

  .static-card--lottery .nx-card-inner .imgContainer-container-wrapper {
    flex-direction: column !important;
    height: 100% !important;
  }

  .static-card.static-card--lottery {
    min-height: 430px !important;
  }

  .static-card.static-card--lottery
    .static-card--content
    .static-card--heading {
    font-size: 26px !important;
    line-height: 32px !important;
  }

  .static-card.static-card--lottery .static-card--content {
    min-height: 263px;
  }
}

.nx-checkbox__control {
  border-color: $progress_bar !important;
}

.newsletter--card .body p .nx-link,
.homepage--card .body p .nx-link,
.voucher--card .body p .nx-link,
.coupon--card .body p .nx-link {
  color: $black-fade !important;
}

.single-voucher--card .voucher--card .media .image-div {
  max-width: 269px !important;
  width: 100% !important;
  max-height: 170px !important;
  height: 100% !important;
  display: flex !important;
  align-content: center !important;
  border-radius: 10px !important;
  background-position: center !important;
}

.single-voucher--card .voucher--card .media {
  background-size: unset !important;
  height: 192px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  position: relative !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.nx-link--small:hover {
  color: $allianz-blue-hover !important;
}

// .single-voucher--card .nx-card.voucher--card {
//   height: unset;
// }

.nx-button--secondary {
  border-color: $progress_bar !important;
}

.nx-switcher__toggle {
  background-color: $top-label !important;
}

.is-checked .nx-switcher__toggle {
  background-color: $progress_bar !important;
}

.nx-button--secondary:active {
  background-color: $deep-ocean-blue !important;
  color: $white !important;
  border-color: transparent !important;
}

.nx-button--secondary:disabled {
  background-color: transparent !important;
  color: rgba(0, 97, 146, 0.4) !important;
  border-color: rgba(0, 122, 179, 0.4) !important;
}

.nx-expansion-panel__chevron {
  color: $black-fade !important;
}

@media (max-width: $sm-breakpoint) {
  .nx-card .media {
    height: 140px !important;
  }

  .cdk-overlay-pane {
    width: 100% !important;
  }

  .prospective-user-overlay {
    text-align: center;
  }

  .prospective-user-overlay .modal--body {
    text-align: left !important;
  }

  .cdk-overlay-pane {
    width: 100% !important;
  }

  .light-image-modal .nx-modal__container .nx-modal__close {
    top: 30px !important;
    right: 36px !important;
  }

  .table-point-account .table--content .credit-card--icon {
    left: 10px !important;
  }

  .shopping-benefits--page .shopping-benefits--filters .nx-pagesearch {
    max-width: 288px !important;
  }

  .direct-cart--page
    .information-block
    .subtotal-block
    .subtotal-block-point
    span.point
    + p {
    position: absolute;
    left: 0;
    top: 23px;
  }

  .product--carousel {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .information-block.nx-grid.nx-grid--media-query {
    padding: 0 !important;
  }

  body {
    padding-top: 60px !important;
  }

  .cart-row--modifier {
    justify-content: center !important;
  }

  .subtotal-block-point {
    display: flex !important;
  }

  .direct-cart--page .cart--header .cart--header-title,
  .table--contents .image--container,
  .table--cart.nx-grid.nx-grid--media-query {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .nx-formfield--type-nx-dropdown.nx-formfield.is-auto-floating {
    margin-left: 1rem !important;
    margin-right: 2rem !important;
  }

  .ticketQuantity-dropdown-container
    .nx-formfield--type-nx-dropdown.nx-formfield.is-auto-floating {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .faq-block
    nx-expansion-panel.nx-expansion-panel--light
    .nx-expansion-panel__header-content
    .nx-expansion-panel__header-title {
    max-width: 248px !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0 !important;
    font-weight: 400 !important;
  }

  .faq-block
    nx-expansion-panel:first-child
    .nx-expansion-panel__header-content {
    align-items: start !important;
  }

  .nx-expansion-panel--regular[_nghost-ng-c2697047931]
    .nx-expansion-panel__header-content {
    align-items: start !important;
  }

  .voucher--page
    nx-message.context-info.mobile-message--container
    .nx-message__content {
    margin-left: 13px !important;
  }

  .voucher--page
    nx-message.context-info.mobile-message--container
    .nx-message__icon.nx-icon--s.ndbx-icon.nx-icon--info-circle {
    margin: 0 !important;
  }

  .shopping-benefits--page
    .shopping-benefits--filters
    .shopping-filter--search
    button {
    color: $white !important;
    background-color: $progress_bar !important;
    border-color: transparent !important;
    position: absolute;
    right: -56px;
    top: -32px;
  }

  .coupon--card,
  .product--card,
  .voucher--card,
  .static--card,
  .homepage--card {
    min-height: 361px !important;
  }

  .cdk-global-overlay-wrapper .cdk-overlay-pane .modal-body--text p {
    padding-top: 1rem !important;
  }

  .faq-block
    .nx-expansion-panel__header-content
    .nx-expansion-panel__header-title {
    max-width: 248px !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0 !important;
    font-weight: 400 !important;
  }

  // .coupon--card .body,
  // .product--card .body,
  // .voucher--card .body,
  // .static--card .body,
  // .homepage--card .body {
  //   min-height: 125px !important;
  // }

  .cart--page .cart--header .cart--header-title {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .is-total-amount {
    display: flex;
    justify-content: start;
    margin-top: -18px !important;
    margin-bottom: 24px !important;
  }

  .is-total-amount p {
    color: $black-fade !important;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 24px;
    height: 24px;
    text-align: left !important;
    margin: 0 !important;
    margin-right: 4rem !important;
  }

  .user-info--block .round--button {
    max-width: 100% !important;
  }

  .coupon--card,
  .product--card,
  .voucher--card,
  .static--card,
  .homepage--card {
    min-height: 266px !important;
  }
}

@media (max-width: $lg-breakpoint) {
  .is-total-amount {
    display: flex;
    justify-content: start;
    margin-top: -32px;
    margin-bottom: 24px !important;
  }

  .is-total-amount p {
    color: $black-fade !important;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 24px;
    height: 24px;
    text-align: left !important;
    margin: 0 !important;
    margin-right: 4rem !important;
  }
}

.shopping-benefits--page
  .shopping-benefits--filters
  .nx-pagesearch
  > div.nx-grid
  .nx-grid__row
  .nx-pagesearch--content {
  max-width: unset !important;
}

@media (min-width: $md-breakpoint) {
  .shopping-benefits--page .shopping-benefits--filters .nx-pagesearch {
    max-width: 373px !important;
  }
}

.nx-copy.nx-copy--normal,
.cart-modal--headline {
  color: $black-fade !important;
}

.nx-button--emphasis {
  color: #fff !important;
  background-color: $orange-color !important;
  border-color: transparent !important;
}

.bg-img-link {
  display: inline !important;
}

.nx-expansion-panel--light .nx-expansion-panel__header-content {
  border-bottom: unset;
}

.directPurchaseAccordion.nx-accordion .nx-expansion-panel--regular {
  box-shadow: unset !important;
}

.directPurchaseAccordion.nx-accordion .nx-expansion-panel__chevron {
  color: $allianz-blue !important;
}

.ticket-category--section .ticket-category--block {
  border-color: $light-grey !important;
}

.nx-dropdown-results__option-checkicon {
  color: $black-fade !important;
}

.category--row.nx-grid__row {
  align-items: center !important;
}

.carousel-options-container .open-lightbox nx-icon {
  color: $white !important;
}

@media (max-width: $md-breakpoint) {
  .faq-link--topblock p {
    text-align: left !important;
  }

  .faq-search p {
    margin: 0 auto 38px 0 !important;
  }

  .faq-search .search-box .search-field .nx-pagesearch .nx-grid {
    padding-left: 0 !important;
    padding-right: 32px !important;
  }

  .nx-expansion-panel__body,
  .nx-expansion-panel--regular .nx-expansion-panel__header-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .nx-grid.nx-grid--media-query {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .round--button {
    max-width: 95% !important;
  }

  .nx-button--block.btn--loadMoreBtn {
    max-width: 95% !important;
  }

  .faq-block .nx-expansion-panel__body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.faq-link--bottomblock div.footer-center-text {
  font-weight: 300 !important;
}

.nx-button--primary {
  color: $white !important;
  background-color: $progress_bar !important;
  border-color: transparent !important;
}

.nx-tab-header__item {
  border-color: $allianz-blue !important;
  border-bottom-width: 2px !important;
  border-bottom-style: solid !important;
}

.context-info .nx-message__icon {
  color: $round-button !important;
}

.transaction-points.nx-copy.nx-copy--normal {
  color: inherit !important;
}

.shopping-benefits--filters
  .nx-tab-header
  > button.nx-tab-header__item:last-child,
.nx-tab-header__item {
  color: $allianz-blue !important;
}

.nx-tab-header__item--active {
  border-width: 4px !important;
  font-weight: 700 !important;
  transition: font-weight 0.3s !important;
  padding-bottom: 4px !important;
}

.round--button {
  border-radius: 25px !important;
  background-color: $round-button !important;
  text-transform: none !important;
  min-height: 40px !important;
  padding: 0.028em 30px !important;
}

.shopping-benefits--filters
  .tab__content
  div.nx-grid__row.nx-justify-content-center
  .round--button.nx-button--medium {
  padding-left: 16px !important;
  padding-right: 16px !important;
  min-width: 288px;
}

.round--button span {
  font-size: 18px !important;
  letter-spacing: 0.2px !important;
}

.nx-expansion-panel__chevron:not(.is-disabled) .nx-expansion-panel__chevron {
  color: $allianz-blue !important;
}

.ticketshop--page
  .ticket-table.sports
  .ticket-table--content
  .ticket-flap.raffle {
  max-width: 158px !important;
}

.footer.footer--voucher .nx-copy.nx-copy--normal {
  color: inherit !important;
}

.cart-delete-modal
  .nx-modal__backdrop
  .nx-modal__position
  .nx-modal__container {
  box-shadow: unset !important;
}

.nx-formfield.has-error:not(.nx-formfield--negative):not(.has-outline)
  .nx-formfield__prefix,
.nx-formfield.has-error:not(.nx-formfield--negative):not(.has-outline)
  .nx-formfield__suffix {
  color: $expired-color !important;
}

.nx-checkbox__control {
  background-color: transparent !important;
  border-width: 2px !important;
  border-color: $top-label !important;
}

.cart-modal .nx-modal__backdrop .nx-modal__position .nx-modal__container {
  box-shadow: unset !important;
}

.nx-formfield.is-focused:not(.has-outline)
  .nx-formfield__input-container:after {
  height: 1px !important;
  background-color: $black-fade !important;
  display: none !important;
}

.shopping-benefits--filters
  .nx-tab-header.nx-tab-header__item--active
  > button.nx-tab-header__item:first-child {
  padding-bottom: 4px !important;
}

.nx-tab-header__item:last-child:before {
  top: -1px !important;
  visibility: hidden !important;
}

.shopping-benefits--filters
  .nx-tab-header
  > button.nx-tab-header__item:first-child {
  padding-bottom: 4px !important;
}

.shopping-benefits--filters
  .nx-accordion
  .nx-expansion-panel__header-content
  .nx-expansion-panel__chevron {
  color: $allianz-blue !important;
}

.Cultural--card .lightbox-mode .nx-modal__container {
  height: 85vh !important;
}

.ticketworldCart-conclusion .conclusion-row .notification-block nx-message {
  background-color: $context-info-bg !important;
}

.cart--page .information-block .title h3 {
  width: 330px !important;
  max-width: 100% !important;
  line-height: 36px !important;
  letter-spacing: 0.3px !important;
  margin-bottom: 10px !important;
}

// h3 {
//   font-size: 30px !important;
//   font-weight: 300 !important;
// }

.nx-formfield__suffix {
  color: #414141 !important;
}

.shopping-benefits--filters
  .nx-expansion-panel--regular
  .nx-expansion-panel__header-content {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.table-point-account .table--content .credit-card--icon {
  position: absolute;
  left: 20px;
}

.advantage-section .advantage-section-contant .left-block h3 {
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 28px !important;
  letter-spacing: 0.2px !important;
  max-width: 100% !important;
  margin-bottom: 16px !important;
}

.nx-autocomplete-panel {
  background-color: $white !important;
  box-shadow: 0 2px 4px rgba(65, 65, 65, 0.5) !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  max-height: 280px !important;
  width: 100% !important;
  border-radius: 4px !important;
}

.nx-autocomplete-option__label {
  padding: 0 12px !important;
  margin: 0 -12px !important;
  color: $black-fade !important;
  font-weight: 400 !important;
}

.newsletter--card .body p .nx-link,
.homepage--card .body p .nx-link {
  color: #414141 !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  letter-spacing: 0.2px !important;
  line-height: 32px !important;
  text-align: center !important;
}

input[type="number"] {
  color: $black-fade !important;
  -webkit-text-fill-color: $black-fade !important;
}

.nx-message__icon {
  margin-right: 16px !important;
}

@media (min-width: $lg-breakpoint) {
  .faq-search .search-box .search-field {
    max-width: 392px;
    width: 100%;
    height: 28px;
  }

  // .coupon--card .body,
  // .product--card .body,
  // .voucher--card .body,
  // .static--card .body,
  // .homepage--card .body {
  //   min-height: 155px !important;
  // }

  .faq-search .search-box .nx-grid.nx-grid--media-query {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .timing-data-row .nx-grid__column--media-query.nx-grid__column-3xlarge-3 {
    max-width: 256px !important;
  }

  .is-total-amount {
    display: flex;
    justify-content: end;
    margin-top: -3.7rem;
    margin-bottom: 2rem;
  }

  .is-total-amount p {
    color: $black-fade !important;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 24px;
    height: 24px;
    text-align: left !important;
    margin: 0 !important;
    margin-right: 4rem !important;
  }

  .single-voucher--card .reward--card .media {
    height: 151px !important;
  }
}

.ticketworldCart-conclusion
  .conclusion-row.nx-grid__row.nx-grid__row--media-query
  .notification-block
  nx-message {
  padding: 31px !important;
}

.nx-radio__circle {
  border-color: $top-label !important;
  border: 2px solid $top-label !important;
}

.nx-radio__input:checked + .nx-radio__label .nx-radio__dot {
  background-color: $progress_bar !important;
}

.complete-personal-information .telephone .nx-copy.nx-copy--normal {
  margin-bottom: 4px !important;
}

.different-shipping-form-element .form-heading {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.horizontal-buttons nx-radio .nx-radio__label--text {
  display: inline-block;
  color: #414141;
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 28px;
}

.nx-radio__circle {
  border-color: #767676 !important;
  border-radius: 50% !important;
  width: 24px !important;
  min-width: 24px !important;
  height: 24px !important;
  position: relative !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 2px solid #767676 !important;
}

.nx-modal__container.ng-trigger-scaleUpDown {
  box-shadow: unset !important;
}

.cdk-global-overlay-wrapper .cdk-overlay-pane .nx-modal__container {
  border-radius: 4px;
  box-shadow: 0 8px 24px rgba(65, 65, 65, 0.35);
  background: $white !important;
  color: $black-fade !important;
  margin: 0 !important;
}

.cdk-global-overlay-wrapper .cdk-overlay-pane {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.nx-modal__actions {
  border-top: unset !important;
  // display: none;
}

.bread-crumbs a:active {
  color: $black-fade !important;
}

.newsletter--card,
.homepage--card {
  min-height: 400px;
  padding: 0;
  margin: 0;
}

.newsletter--card .media,
.homepage--card .media {
  height: 151px !important;
  position: relative !important;
  top: 8px !important;
}

// .single-voucher--card .reward--card .footer--reward {
//   padding-bottom: 0 !important;
// }

// .coupon--card,
// .product--card,
// .voucher--card,
// .static--card,
// .homepage--card {
//   min-height: unset !important;
// }

// .single-voucher--card .voucher--card .body {
//   min-height: 165px !important;
// }

// .single-voucher--card .reward--card .body {
//   min-height: 180px !important;
// }

.nx-button--tertiary:disabled {
  background-color: transparent !important;
  color: rgba(0, 97, 146, 0.4) !important;
  border-color: transparent !important;
}

.nx-pagesearch .nx-formfield__prefix,
.nx-pagesearch .nx-formfield__suffix {
  color: $progress_bar !important;
}

.user-info--block nx-link a {
  align-items: center;
}

.faq-search
  .search-box
  .search-field
  .nx-pagesearch
  .nx-formfield__wrapper
  .nx-formfield__input-container {
  border-color: $allianz-blue !important;
}

header .nx-header nx-header-brand img {
  margin-top: -8px !important;
}

.ticket-detail-page .main-ticket-page--description {
  margin-bottom: 0 !important;
}

.ticket-payment-complete--page
  .sale-success--card-container
  .purchase-msg--text {
  margin-bottom: 0 !important;
}

.ticket--carousel .lightbox-mode .nx-modal__container {
  height: 75vh !important;
}

.nx-card .nx-card-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.footer-top .nx-card .nx-card-inner {
  align-items: center !important;
}

.change-email-modal.ng-star-inserted.nx-modal__content .nx-modal__actions {
  justify-content: center;
}

.cart-remove-actions.nx-modal__actions button {
  width: 256px;
}

.cart-remove-actions.nx-modal__actions button:first-child {
  margin-right: 32px !important;
}

.cart-remove-actions.nx-modal__actions {
  flex-wrap: wrap !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: auto !important;
  display: flex !important;
  justify-content: center !important;
}

@media only screen and (max-width: $lg-breakpoint) {
  .cart-remove-actions.nx-modal__actions button:first-child {
    margin-right: 0 !important;
    margin-bottom: 16px !important;
  }

  .cart-remove-actions.nx-modal__actions button {
    width: 100%;
  }
}

.ticketshop-address-info .nx-margin-bottom-s.nx-link.block.nx-link--small {
  margin-top: 16px !important;
}

.nx-button--tertiary:active {
  background-color: $carousel-bg !important;
  color: $allianz-blue !important;
  border: transparent !important;
}

.remove-action-cta {
  display: none !important;
}

.cdk-global-overlay-wrapper .nx-modal__close {
  outline: none !important;
}

@media (min-width: $sm-breakpoint) and (max-width: $md-breakpoint) {
  .shopping-benefits--page
    .shopping-benefits--filters
    .nx-pagesearch
    .nx-pagesearch__actions {
    position: absolute;
    right: -100%;
    top: 19px;
  }
}

@media (min-width: $sm-breakpoint) and (max-width: $lg-breakpoint) {
  .static-card.static-card--lottery .static-card--content {
    max-width: 100%;
  }

  .user-info--block .round--button,
  .user-info--block .round--button .nx-button__content-wrapper {
    max-width: 100% !important;
  }

  .user-info--block .round--button .nx-button__content {
    flex-wrap: wrap !important;
  }

  .static-card--lottery .nx-card-inner .imgContainer-container-wrapper {
    flex-direction: row !important;
    height: 100%;
  }

  .static-card,
  .static-card.static-card--lottery {
    min-height: 100% !important;
  }
}

.newsletter--card .body,
.homepage--card .body {
  margin-top: 5px !important;
}

.nx-button--primary,
.nx-button--tertiary,
.nx-button--secondary {
  box-shadow: unset !important;
}

.lottery-address--page .button-row {
  justify-content: center !important;
}

.homepage--card .body {
  padding: 0 32px 44px !important;
  text-align: center !important;
}

.homepage--card .body a {
  text-align: center !important;
  display: block !important;
}

.homepage--card .media {
  background-color: $white;
  height: 151px;
  background-size: 80px 80px;
  background-position: center 44px;
}

.homepage--card .body > div {
  max-width: 260px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
}

.footer.footer--homepage {
  border-top: none !important;
  padding: 0 0 48px !important;
  height: 71px !important;
}

.homepage--card .body > div p {
  color: $black-fade !important;
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 24px;
  text-align: center;
  -webkit-hyphens: none;
  hyphens: none;
}

.cart-session-modal .nx-modal__actions {
  justify-content: center !important;
}

.light-image-modal {
  max-width: 100% !important;
  width: 100% !important;
  height: 100vh !important;
}

.light-image-modal .nx-modal__content {
  height: 92vh !important;
  max-height: 100vh !important;
}

.light-image-modal .lightbox-image {
  max-width: 100% !important;
  width: 100% !important;
}

.light-image-modal .nx-modal__close {
  top: 70px !important;
  right: 3.5% !important;
  z-index: 9999 !important;
}

.light-image-modal
  .nx-modal__container.ng-trigger.ng-trigger-modalContainer.ng-trigger-slideInOut.ng-star-inserted {
  border-radius: unset !important;
  padding-left: unset !important;
  padding-right: unset !important;
  overflow-x: hidden !important;
}

.light-image-modal .nx-modal__container #Sports--card .media-list {
  height: 100% !important;
}

.nx-modal__actions {
  margin-bottom: 0 !important;
}

.coupon--page .nav-bar-sticky,
.cart--page .nav-bar-sticky {
  position: fixed !important;
  width: 100%;
}

.coupon--page .cart-row--container,
.cart--page .main--whitebg {
  padding-top: 50px !important;
}

.copy-success--btn.nx-button--cta {
  background-color: $allianz-green !important;
  color: $white !important;
}

.is-auto-floating.has-error .nx-formfield__label {
  color: $expired-color !important;
}

.is-auto-floating.has-error.is-floating.is-filled .nx-formfield__label {
  color: $black-fade !important;
}

.is-auto-floating.has-error.is-floating.is-focused .nx-formfield__label {
  color: $black-fade !important;
}

.nx-formfield.is-focused:not(.nx-formfield--negative):not(.has-error) {
  color: $allianz-blue !important;
}

:focus {
  box-shadow: unset !important;
}

.nx-dropdown-item--active .nx-dropdown-results__option-label {
  outline: 2px dotted $allianz-blue !important;
  outline-offset: 4px;
  outline-color: $allianz-blue !important;
}

.nx-radio__circle,
.nx-checkbox__control,
.nx-link.nx-link--small a {
  box-shadow: unset !important;
}

.lottery-page--error {
  .context-error {
    background-color: $error-message-bg !important;
  }
}

.static-card--lottery {
  .nx-card-inner {
    width: 100%;

    .imgContainer-container-wrapper {
      display: flex;
      width: 100%;
    }
  }
}

.nx-button--primary.nx-button--negative {
  background-color: $white !important;
  border-color: $white !important;
  color: $allianz-blue !important;
}

.nx-button--primary.nx-button--negative:hover:not(:disabled):not(:active) {
  background-color: $light-grey !important;
  border-color: $light-grey !important;
  color: $allianz-blue !important;
}

.allianzProgram--main .nx-copy.nx-copy--normal {
  margin-left: auto !important;
  margin-right: auto !important;
}

.regular-user-overlay .modal--body {
  text-align: center !important;
}

.regular-user-overlay .modal--body > p {
  text-align: center !important;
}


// Navbar 

@media only screen and (min-width: 704px) {
  .nx-header__navigation {
    .nx-header__navigation-items {
      gap: 44px;
      justify-content: center !important;
    }
  }

  .nav-bar-sticky {
    .nx-grid--max-width {
      .nx-header__row {
        width: 100% !important;
      }
    }
  }

  .navigation-row {
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (min-width: 704px) {
  .navbar.sticky .nx-grid {
    overflow-x: scroll;
    margin-left: 16px !important;
  }
  
}

@media only screen and (min-width: 1300px) {
  .navbar.sticky .nx-grid {
    width: 100%;
    max-width: 100% !important;
    overflow: unset !important;
    margin-left: unset !important;
  }
}
.text-green {
  color: green;
}